import React from "react";

import { injectIntl } from 'gatsby-plugin-intl';

import BlogPost from "./blog-post";
import Section from "./section";
import LoadingBlock from "./loading-block";
import "../styles/blog.less";

class Blog extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            "posts": [],
            "loading": true
        };
    }

    componentDidMount()
    {
        const fetchLimit = this.props.fetchLimit;
        let lang = this.props.intl.locale;
        switch (lang)
        {
            case 'es':
                lang = 'es_ES';
                break;
            case 'en':
                lang = 'en_GB';
                break;
            case 'pt':
                lang = 'pt_BR';
                break;
            default:
                lang = 'es_ES';
                break;
        }
        const url = 'https://script.adventurees.com/get_blog_posts.php?fetch_limit='+fetchLimit+'&lang='+lang; // PROD
        //const url = 'http://scripts.localhost/get_blog_posts.php?fetch-limit='+fetchLimit+'&lang='+lang; // Fernando
        fetch(
            url, 
            {
                method: 'GET',
                crossDomain: true,
                headers:{
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res => res.json())
        .then(
            response => {
                // Aquí entrará aunque la respuesta sea un error 404 o un error 500, así que hay que gestionar los distintos casos para mostrar un mensaje u otro
                // Pero hay que hacerlo dentro de este if, porque al parecer entra en el catch y también en el then cuando hay errores graves (no he conseguido solucionarlo aún)
                if (response.result)
                {
                    this.setState({"posts": response.posts, "loading": false});
                }
            }
        )
        .catch(
            (err) => {
                console.log(err);
                this.setState({"posts": [], "loading": false})
            }
        )
    }

    render()
    {
        if (this.state.loading)
        {
            return <div className="blog">
                <LoadingBlock />
            </div>
        }
        return <div className="blog">
            {this.state.posts.length !== 0 && 
                <Section title={this.props.title}>
                    {this.state.posts.map((post, index) => {
                        return <BlogPost key={index} post={post} />
                    })}
                </Section>}
            </div>
    }
}

export default injectIntl(Blog);
