import React from "react";
import { injectIntl } from "react-intl";

import "../styles/loading-page-block.less";

const LoadingBlock = (props) => {
    return <div className="loading-page-block">
        <div>
            <img src="/images/common/loading.gif" alt="Loading animation" />
        </div>
    </div>
}

export default injectIntl(LoadingBlock);
