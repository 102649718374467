import React from "react";
import { changeLocale, injectIntl } from "gatsby-plugin-intl";

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Blog from '../components/blog';

import "../styles/news.less";

const News = (props) => {
    /*if (props.intl.locale !== 'es')
    {
        changeLocale('es');
    }*/
    return <Layout staticHeader className="news">
        <SEO 
            title={props.intl.formatMessage({id: "Latest news - Adventurees Alliance"})} 
            description={props.intl.formatMessage({id: "News related to the entrepreneurship sector and investment related to the activity we carry out"})}
            location={props.location}
        />

        <Blog fetchLimit={0} title={props.intl.formatMessage({id: "Latest news"})} />
    </Layout>
}

export default injectIntl(News);