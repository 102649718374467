import React from "react";
import PropTypes from 'prop-types';

import { injectIntl } from "react-intl";

const BlogPost = (props) => (
    <article className="post" key={props.key}>
        <div className="row">
            <div className="col-lg-2">
                <div className="post-image">
                    {props.post.image_path &&
                        <img src={props.post.image_path} alt={props.post.title} srcset="" />
                    }
                    {!props.post.image_path &&
                        <img src={"/images/common/main-logo.png"} alt={props.post.title} />}
                </div>
                <div className="post-date">
                    <p className="post-day">{props.post.display_date.day}</p>
                    <p>{props.post.display_date.month}</p>
                    <p>{props.post.display_date.year}</p>
                </div>
            </div>
            <div className="col-lg-10">
                <h3 className="post-title">
                    {props.post.title}
                </h3>
                <div className="post-content" dangerouslySetInnerHTML={{ __html: props.post.content }}></div>
                {props.post.link && 
                    <div className="post-link">
                        <a target="_blank" rel="noopener noreferrer" className="btn-primary" href={props.post.link}>{props.intl.formatMessage({id: 'Go to the news'})}</a>
                    </div>
                }
            </div>
        </div>
    </article>
)

BlogPost.propTypes = {
    key: PropTypes.string.isRequired,
    post: PropTypes.shape({
        image_path: PropTypes.string,
        display_date: PropTypes.shape({
            day: PropTypes.string.isRequired,
            month: PropTypes.string.isRequired,
            year: PropTypes.string.isRequired
        }).isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        link: PropTypes.string
    }).isRequired,
};

export default injectIntl(BlogPost);
